import { FC, useContext, useEffect, useState } from 'react';
import MuiContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import MuiTypography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';

import { styles } from '@/components/MagicLinkInvite/MagicLinkInvite.styles';
import { InviteStepCtx } from '@/components/Steps';
import { useAppSelector } from '@/hooks';
import { selectCompanyId } from '@/redux/features/authSlice';
import CompanyRequests from '@/requests/company.requests';
import { Button } from '@/shared/Button';
import { FlexBox } from '@/shared/FlexBox';
import { Snackbar } from '@/shared/Snackbar';
import { TextField } from '@/shared/TextField';

import { useClipboard } from '../../hooks/useClipboard';


type MagicLinkInviteComponentProps = {
  onLinkCopied: () => void;
}

type SnackbarState = {
  open: boolean;
  message: string;
}

export const MagicLinkInviteComponent: FC<MagicLinkInviteComponentProps> = ({ onLinkCopied }) => {
  const { copyToClipboard } = useClipboard();
  const { t } = useTranslation();
  const { company } = useAppSelector(state => state.auth);
  const companyId = useAppSelector(selectCompanyId);
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({ open: false, message: '' });
  const { setDidCopyLink } = useContext(InviteStepCtx);

  useEffect(() => {
    // If this component was rendered, it means that the user was exposed to the Magic Link EXP
    CompanyRequests.putCompanySettings(companyId, { custom: { hasInviteLink: true }});
  }, []);

  const handleCopyLink = async () => {
    onLinkCopied();
    setDidCopyLink(true);
    const { success } = await copyToClipboard(company?.inviteLink || '');
    const message = success
      ? t('onboarding:invite_members_step.magic_link.link_copied')
      : t('onboarding:invite_members_step.magic_link.link_not_copied');
    setSnackbarState({ open: true, message });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <>
      <FlexBox column>
        <div css={ styles.TextWrapper }>
          <FlexBox justifyContent="left" alignItems="left" columnGap={ 1 }>
            <MuiTypography variant="body2" color="info" css={ styles.SubTitle }>
              { t('onboarding:invite_members_step.magic_link.description') }
            </MuiTypography>
          </FlexBox>
        </div>

        <FlexBox columnGap={ 1 } css={ styles.magicWrapper } pt={ 2 }>
          <TextField
            variant="filled"
            size="small"
            disabled
            fullWidth
            defaultValue={ company?.inviteLink }
            InputProps={{
              startAdornment: <InputAdornment position="start"><LinkIcon color="info" /></InputAdornment>,
            }}
          />
          <Button
            variant="outlined"
            size="medium"
            uppercase
            css={ styles.Button }
            onClick={ handleCopyLink }
            trackEvent={ 'Copied url on Invite Screen' }
            trackProps={{ invite_url: company?.inviteLink }}
            startIcon={ <MuiContentCopyIcon color="primary" /> }
          >
            { t('onboarding:invite_members_step.magic_link.copy_btn') }
          </Button>
        </FlexBox>
      </FlexBox>

      <Snackbar
        open={ snackbarState.open }
        onClose={ handleCloseSnackbar }
        message={ snackbarState.message }
      />
    </>
  );
};
