import React, { FC, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useFeature } from '@growthbook/growthbook-react';
import { Box } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';

import { MagicLinkInviteSimplified } from '@/components/MagicLinkInvite';
import { MagicLinkInviteComponent } from '@/components/MagicLinkInvite/MagicLinkInviteComponent';
import { InviteOptionsProps, InviteStepCtx } from '@/components/Steps';
import { AddMoreButton } from '@/components/Steps/InviteTeamStep/AddMoreBtn';
import { useOnboardingTask, useTracking } from '@/hooks';
import { Button, ButtonProps, LoadingButton } from '@/shared/Button';
import { Divider } from '@/shared/Divider';
import { FlexBox } from '@/shared/FlexBox';
import { Tooltip } from '@/shared/Tooltip';
import { ExperimentProps } from '@/types/tracking.types';

import { useDownloadApps } from '../../../hooks/useDownloadApps';

export const InviteOptions: FC<InviteOptionsProps> = ({
  flow,
  membersToInvite,
  onAddMoreClick,
  onSendInvites,
  renderMemberField,
}) => {
  const { t } = useTranslation('onboarding');
  const { goToNextStep } = useOnboardingTask(flow);
  const { didCopyLink } = useContext(InviteStepCtx);
  const { trackOnboardingStep } = useTracking(flow);
  const { formState: { isSubmitting, isValid, isDirty }} = useFormContext();
  const showHybridInvitePage = useFeature<ExperimentProps>('invite-page-onboarding');
  const hybridInvitePageExp = showHybridInvitePage.value?.variationid as 'A' | 'B' | undefined;
  const { executeBrowserPromoTracking } = useDownloadApps(flow);
  const [isMembersValidOrEmpty, setIsMembersValidOrEmpty] = useState(false);

  useEffect(() => {
    const checkMembersValidity = () => {
      let atLeastOneValid = false;
      const EMAIL_PATTERN_REGEX_VALIDATION = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      for (const member of membersToInvite) {
        if (!member.email) {
          continue;
        }

        if (EMAIL_PATTERN_REGEX_VALIDATION.test(member.email)) {
          atLeastOneValid = true;
        } else {
          return false;
        }
      }
      return atLeastOneValid;
    };

    const result = checkMembersValidity();
    setIsMembersValidOrEmpty(result);
  }, [membersToInvite]);


  const handleSkipBtnClick = async () => {
    await executeBrowserPromoTracking();
    await trackOnboardingStep('Skipped Invite User during Onboarding');
    goToNextStep();
  };

  const onLinkCopied = async () => {
    //
  };

  const dividerStyle = css`
    display: flex;
    align-items: center;
    width: 100%; /* Ensures it stretches to the full width */
    text-align: center;
    //margin: 20px 0;

    .line {
      flex-grow: 1;
      height: 1px;
      background-color: #EDEEF2; /* Ensures the line is visible */
    }

    .divider-text {
      font-size: 14px;
      color: #A4A7B2;
      padding: 0 10px; /* Spacing around the text */
      white-space: nowrap; /* Ensures text does not wrap */
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  `;


  return (
    <form
      className="d-flex-col"
      onSubmit={ onSendInvites } noValidate
      aria-label="Invite team by email"
      style={{ height: '100%', rowGap: '36px' }}
    >
      <Box>
        <FlexBox column rowGap={ 3 } mb={ 2 }>
          {
            membersToInvite.map((member, i) => renderMemberField(member, i, hybridInvitePageExp))
          }
        </FlexBox>
        <AddMoreButton
          onClick={ onAddMoreClick }
          variant="exp"
          buttonText={ hybridInvitePageExp === 'A' ? 'current' : 'exp' }
        />
      </Box>

      { hybridInvitePageExp === 'A' ? (
        <Box
          display="flex" justifyContent="space-between" mt="auto" flexDirection={{ zero: 'column', sm: 'row' }}>

          <MagicLinkInviteSimplified />

          <Divider sx={{ display: { sm: 'none' }}} mt={ 2 } mb={ 2 } lineColor="info.lighter" />

          <Box display="flex" gap={ 2 } flexDirection={{ zero: 'column-reverse', sm: 'row' }}>
            <RemindMeLaterBtn onClick={ handleSkipBtnClick }>
              { t('onboarding:invite_members_step.skip_btn') }
            </RemindMeLaterBtn>
            <Tooltip
              title={ t('onboarding:invite_members_step.tooltip_hover_btn.message') }
              disableFocusListener
              disableHoverListener={ (isValid && isDirty) || didCopyLink }
              enterTouchDelay={ 0 }
            >
              <Box flex="1 1 auto">
                <LoadingButton
                  variant="contained"
                  type="submit"
                  disableElevation={ false }
                  loading={ isSubmitting }
                  disabled={ (!isDirty || !isValid) && !didCopyLink }
                  fullWidth
                >
                  { t('common:next') }
                </LoadingButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <>
          <div css={ dividerStyle }>
            <div className="line" />
            <span className="divider-text">OR</span>
            <div className="line" />
          </div>
          <MagicLinkInviteComponent onLinkCopied={ onLinkCopied } /><Box
            display="flex" justifyContent="flex-end" mt="auto" flexDirection={{ zero: 'column', sm: 'row' }}>


            <Divider sx={{ display: { sm: 'none' }}} mb={ 1 } lineColor="info.lighter" />

            <Box display="flex" gap={ 2 } flexDirection={{ zero: 'column-reverse', sm: 'row' }}>
              <RemindMeLaterBtnB onClick={ handleSkipBtnClick }>
                { t('onboarding:invite_members_step.remindMeLater_btn') }
              </RemindMeLaterBtnB>
              <Tooltip
                title={ t('onboarding:invite_members_step.tooltip_hover_btn.message') }
                disableFocusListener
                disableHoverListener={ (isValid && isDirty) || didCopyLink }
                enterTouchDelay={ 0 }
              >
                <Box flex="1 1 auto">
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    disableElevation={ false }
                    loading={ isSubmitting }
                    disabled={ !(isMembersValidOrEmpty || didCopyLink) } // Stable state used here
                    fullWidth
                  >
                    { t('common:next') }
                  </LoadingButton>
                </Box>
              </Tooltip>
            </Box>
          </Box></>
      )
      }
    </form>
  )
  ;
};

const RemindMeLaterBtn = styled((props: ButtonProps) =>
  <Button variant="text" color="info" { ...props } />)(() => css`
  display: block;
  flex: 1 1 auto;
`);

const RemindMeLaterBtnB = styled((props: ButtonProps) =>
  <Button variant="text" color="info" { ...props } />)(() => css`
  display: block;
  flex: 1 1 auto;
  text-transform: none;
  color: #585A6E;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 500;
`);
