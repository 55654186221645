import { FC } from 'react';
import { createPortal } from 'react-dom';
import { css } from '@emotion/react';
import Image from 'next/image';

type LoaderProps = {
  show: boolean;
};

const styles = {
  root: css`
    background: rgba(255, 255, 255, 0.8); // Slight transparency for better UX
    width: 100%;
    height: 100%;
    z-index: 1500;
    position: fixed; // Use fixed instead of absolute for better control
    top: 0;
    left: 0;

    & img {
      width: 114px;
      height: 105px;
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); // Center the image
    }
  `,
};

export const Loader: FC<LoaderProps> = ({ show }) => (
  <>
    { show && typeof window !== 'undefined' &&
      createPortal(
        <div css={ styles.root }>
          <Image
            src="/assets/loading.gif"
            alt="Loading..."
            width={ 108 }
            height={ 108 }
            priority
            loading="eager"
          />
        </div>,
        window.document.body,
      ) }
  </>
);
