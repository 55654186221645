import { ReactElement } from 'react';

import { FlowModel } from '@/models/flow.model';
import { Member } from '@/types/invite-member.type';
import { InviteMemberStep, StepInfo } from '@/types/steps.type';

import { InviteTeamStep } from './InviteTeamStep';

export const InviteTeamStepInfo: StepInfo<InviteMemberStep> = {
  Component: InviteTeamStep,
  id: 'sendInvitesStep',
  stepName: 'Invite Employees Screen',
  title: 'Invite your team',
  desc: 'Unlock the full power of Time Doctor together.',
};

export type InviteOptionsProps = {
  flow: FlowModel;
  membersToInvite: Member[];
  onAddMoreClick: () => void;
  onSendInvites: () => void;
  renderMemberField: (member: Member, index: number, hybridInvitePageExp: 'A' | 'B' | undefined) => ReactElement;
}

export { InviteStepCtx } from './InviteTeamStep';
